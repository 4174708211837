<template>
    <div class="content">
        <div class="search_cont">
            <div class='search_item'>
                <!-- <div class='search_herad'>姓名:</div> -->
                 <Input  style="width:200px;margin-left:0px;" placeholder="请输入姓名账户编号"  v-model="pageForm.keywords" ></Input> 
            </div>
            <!-- <div class='search_item'>
                <div class='search_herad'>电话:</div>
                <Input  style="width:200px;margin-left:12px;" placeholder="请输入电话"  v-model="phone" ></Input>
            </div>
            <div class='search_item'>
                <div class='search_herad'>时间:</div>
                <DatePicker type="date" placeholder="请选择日期 " v-model="searchTime" style="width: 200px;margin-left:8px"></DatePicker>
            </div>
            <div class='search_item'>
                <div class='search_herad'>预约商品:</div>
                <Select v-model="store_id"  style="width:180p x;margin-left:8px" clearable >
                    <Option v-for="item in storeList" :value="item.id" :key="item.id">{{  item.name }}</Option>
                </Select>   
            </div> -->
            <div class='search_item' style="margin-left:8px">
                <Button @click="searhList()" class="btn">搜索</Button>
            </div>
        </div>
        <Button @click="seeBtn('add',{})" style="margin-bottom:20px;background:#4877E8;color:#fff;border-color:#4877E8">新增</Button>
        <Table :columns="columns12" :data="data6">
            <template slot-scope="{ row }" slot="goods_picture">                
                <div v-for="(item,index) in row.goods_picture" class="img-list" :key='index'>                   
                    <img :src="item" >
                </div>
            </template>
            <template slot-scope="{ row }" slot="is_able">                
               <!-- {{row.is_able?'上架中':'下架中'}} -->
               <i-switch   v-model="row.is_able"  @on-change="closeNumber($event,row)" :true-value="1" :false-value="0">                          
                </i-switch>
                <!-- {{ row.is_able ? '上架中' :'下架中' }} -->
            </template>
            <template slot-scope="{ row }" slot="coupon_count">     
                <Button @click="goshowcoupon(row.id)">{{row.withusecoupon_count}}</Button>               
            </template>      
            <template slot-scope="{ row }" slot="order_count">     
                <Button @click="goshowOrder(row.id)">{{row.order_count}}</Button>               
            </template>   
            <template slot-scope="{ row }" slot="share_count">     
                <Button @click="goshowShare(row.id)">{{row.share_count}}</Button>               
            </template>   
            <template slot-scope="{ row }" slot="relatives_count">     
                <Button @click="goshowRelatives(row.id)">{{row.relatives_count}}</Button>               
            </template>         
            <template slot-scope="{ row }" slot="action">
                <!-- <Button  @click="goFWM(row)" style="color:#999999;background: unset;" type="text">生成防伪码</Button> -->
                <Button @click="seeBtn('edit',row)" style="color:#12B130;background: unset;" type="text">编辑</Button>
                 <Button @click="member_del(row.id)" style="color:red;background: unset;" type="text">删除</Button>
                <Button @click="qrcode(row)" style="color:#12B130;background: unset;" type="text">二维码</Button>
            </template> 
            
            <!-- 
            <template slot-scope="{ row }" slot="sex">
                <div>{{row.sex==1?'男':'女'}}</div>
            </template>
            <template slot-scope="{ row }" slot="book_status">                
                <div>{{row.book_status == 1?'已核销':'待核销'}}</div>
            </template>   
            <template slot-scope="{ row }" slot="book_time">                
                <div>{{row.book_date}}--{{row.book_time}}</div>
            </template>    
               
            <template slot-scope="{ row }" slot="action">
                <Button v-if="row.book_status == 0 " @click="goHexiao(row)" style="color:#3C90EE;background: unset;" type="text">核销</Button>
            </template> -->
            
                <!-- <Button @click="seeBtn('look',row)" style="color:#3C90EE;background: unset;" type="text">查看</Button>
                <Button @click="seeBtn('edit',row)" style="color:#12B130;background: unset;" type="text">编辑</Button>
                <Button v-if="row.status==1" @click="disable(1,row.uid)" style="color:#999999;background: unset;" type="text">禁用</Button>
                <Button v-if="row.status==0" @click="disable(0,row.uid)" style="color:#FA2342;background: unset;" type="text">启用</Button> -->
        </Table>
        <br />
        <Page :total="total" :current="pageForm.page" @on-change="pageChange" show-total show-elevator style="text-align:right" />

         <!-- 查看弹框 -->
        <Modal v-model="seemodal" @on-cancel="closeForm" :mask-closable="false" width="410">
            <p slot="header" style="text-align:center">{{seetitle}}</p>
            <div class="modal_contene">
                <Form label-position="left" :label-width="100">
                    <FormItem label="姓名">
                        <Input placeholder="请输入姓名" v-model="formData.name" style="width:200px"></Input>                       
                    </FormItem>
                    <FormItem label="手机号">
                        <Input placeholder="请输入手机号" v-model="formData.account" style="width:200px"></Input>                       
                    </FormItem>
                    <FormItem label="密码">
                        <Input placeholder="请输入密码" v-model="formData.pwd"  type="password" style="width:200px"></Input>                       
                    </FormItem>
                    <!-- <FormItem label="套餐金额">
                        <InputNumber  placeholder="请输入商品套餐金额" v-model="formData.money" style="width:200px"></InputNumber >
                    </FormItem>
                    <FormItem label="年限（整数）">
                        <InputNumber  placeholder="请输入套餐年限（整数）" v-model="formData.years" style="width:200px"></InputNumber >
                    </FormItem> -->
                    <!-- <FormItem label="套餐原价">
                        <InputNumber  placeholder="请输入商品套餐原价" v-model="formData.original_money" style="width:200px"></InputNumber >
                    </FormItem>
                    <FormItem label="分销金额">
                        <InputNumber  placeholder="请输入商品套餐分销金额" v-model="formData.share_money" style="width:200px"></InputNumber >
                    </FormItem>                   
                     <FormItem label="商品图片">
                        <div  v-if="accessoriesList.length!=0"
                        style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;">
                            <div class="img-list" v-for="(item,index) in accessoriesList" :key="index">                     
                                <img :src="item.src" >
                                <div class="close" @click="delThis(index)"><Icon type="ios-close-circle-outline" size="30" /></div>
                            </div>
                        </div>
                        <div v-else>
                            <Upload
                                :show-upload-list="false"
                                :headers='headers'
                                :name="'file'"
                                :action="pictureAction"
                                :on-format-error="handleFormatError"                                
                                :format="['jpg','jpeg','png']"
                                :on-success="uploadImgSuccess">
                                <div class="upload-style">
                                <Icon type="ios-add" size="60"/>
                                <Button size="small" style="margin-bottom:20px;background:#4877E8;color:#333;border-color:#4877E8" type="primary">上传图片</Button>
                                </div>
                            </Upload>
                        </div>
                    </FormItem>     
                    <FormItem label="商品详情">
                        <editor :edtor-con="formData.goods_text" :index="1" @getContent="getEnContent" 
                             style="width:550px"/>      
                    </FormItem>                   -->
                </Form>
            </div>
            <div slot="footer">
                <Button @click="addBtn" class="btn">确定</Button>
            </div>
        </Modal>
        <Modal v-model="orderModal" @on-cancel="orderModal = false" :mask-closable="false" width="800" :footer-hide="true">
            <p slot="header" style="text-align:center">会员订单</p>
            <div class="modal_contene">
                <!-- <Button @click="goFWM()" style="margin-bottom:20px;background:#4877E8;color:#fff;border-color:#4877E8">新增</Button> -->
                <Table :columns="orderColumns" :data="orderList">                            
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="subTemplete(row.id)" v-if="row.is_refund" style="color:red;background: unset;" type="text">退单</Button>
                    </template> 
                    <template slot-scope="{ row }" slot="goods">
                        {{ row.goods.name }}
                    </template> 
                    <template slot-scope="{ row }" slot="member">
                        {{ row.member.name }}
                    </template>                    
                </Table>
                <br />
                <Page :total="ordertotal" :current="orderPageForm.page" @on-change="orderPageChange" show-total show-elevator style="text-align:right" />
            </div>           
        </Modal>
        
        <Modal v-model="couponModal" @on-cancel="couponModal = false" :mask-closable="false" width="800" :footer-hide="true">
            <p slot="header" style="text-align:center">会员卡卷</p>
            <div class="modal_contene">
                <!-- <Button @click="goFWM()" style="margin-bottom:20px;background:#4877E8;color:#fff;border-color:#4877E8">新增</Button> -->
                <Table :columns="couponColumns" :data="goodsListCoupon">                            
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="write_off(row.id)" v-if="!row.original_status" style="color:red;background: unset;" type="text">核销</Button>
                    </template> 
                    <template slot-scope="{ row }" slot="hexiao_time">
                        <span v-if="row.original_status == 1">{{row.updated_at}}</span>
                    </template> 
                    <template slot-scope="{ row }" slot="coupon">
                        {{ row.coupon.name }}
                    </template> 
                    
                </Table>
                <br />
                <Page :total="goodstotal" :current="goodsPageForm.page" @on-change="goodsPageChange" show-total show-elevator style="text-align:right" />
            </div>           
        </Modal>

        <Modal v-model="shareModal" @on-cancel="shareModal = false" :mask-closable="false" width="800" :footer-hide="true">
            <p slot="header" style="text-align:center">会员分销</p>
            <div class="modal_contene">
                <!-- <Button @click="goFWM()" style="margin-bottom:20px;background:#4877E8;color:#fff;border-color:#4877E8">新增</Button> -->
                <Table :columns="shareColumns" :data="shareList">                            
                    <template slot-scope="{ row }" slot="action">
                        <!-- <Button @click="member_del_relatives(row.id)" style="color:red;background: unset;" type="text">删除</Button> -->
                    </template> 
                    <template slot-scope="{ row }" slot="share">
                        {{ row.share.name }}
                    </template>           
                    <template slot-scope="{ row }" slot="goods">
                        <div v-if="row.order">
                            {{  row.order.goods.name }}
                        </div>
                        
                    </template>           
                    <template slot-scope="{ row }" slot="coupon">
                        {{ row.coupon.name }}
                    </template> 
                    <template slot-scope="{ row }" slot="member">
                        {{ row.member.name }}
                    </template>                                        
                </Table>
                <br />
                <Page :total="sharetotal" :current="sharePageForm.page" @on-change="sharePageChange" show-total show-elevator style="text-align:right" />
            </div>           
        </Modal>
        <Modal v-model="relativesModal" @on-cancel="relativesModal = false" :mask-closable="false" width="800" :footer-hide="true">
            <p slot="header" style="text-align:center">亲属卡</p>
            <div class="modal_contene">
                <Button @click="goFWM()" style="margin-bottom:20px;background:#4877E8;color:#fff;border-color:#4877E8">新增卡卷</Button>
                <Table :columns="relativesColumns" :data="relativesList">                            
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="member_del_relatives(row.id)" style="color:red;background: unset;" type="text">删除</Button>
                    </template> 
                    <template slot-scope="{ row }" slot="member">
                        {{ row.relatives.name }}
                    </template>     
                    <template slot-scope="{ row }" slot="member1">
                        {{ row.relatives.account }}
                    </template>                 
                </Table>
                <br />
                <Page :total="relativestotal" :current="relativesPageForm.page" @on-change="relativesPageChange" show-total show-elevator style="text-align:right" />
            </div>           
        </Modal>

        <Modal v-model="showfwm" @on-cancel="showfwm = false" :mask-closable="false" width="510">
            <p slot="header" style="text-align:center">添加亲属人</p>
            <div class="modal_contene">
                <Form label-position="left" :label-width="100">    
                    <!-- <FormItem label="卡卷" style="margin-top: 24px;">
                        <Select   v-model="submitformdata.coupon_id" clearable style="width: 200px"  placeholder="请选择卡卷">
                            <Option  v-for="item in couponSelectList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>   
                    </FormItem> -->
                    <FormItem label="账户手机号" style="margin-top: 24px;">                            
                        <Input v-model="submitformdata.phone" placeholder="请输入亲属账户"   style="width: 300px"></Input>     
                    </FormItem>  
                </Form>
            </div>
            <div slot="footer">
                <Button @click="addfwm" class="btn">确定</Button>
            </div>
        </Modal>
       
    </div>
</template>

<script>
// http://abc.api.com/coupon/list?limit=30&page=1&keywords=
    import config from '@/config';

    import editor from "@/components/editor/editor.vue";
    import { getCookies } from '@/utils/helper'
    import { 
        get_member_list,
        get_qrcode,
        member_create,
        member_del,
        order_refund,//退单
        get_order_member_list,
        get_member_coupon_list,
        get_member_share_list,

        get_member_relatives_list,
        write_off,
        member_add_relatives,
        member_del_relatives
        
        
        // goods_able,
        // goods_info,
        // get_coupon_select,
        // get_member_coupon_list,goods_create_coupon,member_del_relatives 
    } from "@/api/index";
    export default {
        
        components: { editor },
        data(){
            return{
                header:{
                    'authori-zation':getCookies('token')
                },
                baseURL: config.apiUrl + '/adminapi/user/upload',
                baseURLs: config.apiUrl + '/adminapi/user/edit_album',
                avatarView:'',
                total:0,

                pageForm:{
                    page:1,
                    limit:10,
                    keywords:'',
                    date:'',
                    store_id:'',
                },
                submitData: { // 这里是需要携带的数据
                    uid:null
                },
                
                keywords:'',
                phone:'',
                showfwm:false,
                fwmnum: null,
                searchTime:'',
                store_id:'',
                contentTexe:'',
                member_id:'',
                GenderList: [
                    {
                        value: 1,
                        label: '男'
                    },
                    {
                        value: 2,
                        label: '女'
                    }
                ],
                uid:null,
                accessoriesList:[],
                ImgURL: config.apiUrl,
                headers:{'teeth-token' : getCookies('teeth-token')},
                pictureAction: config.apiUrl + '/other/uploads',
                formData:{
                    id: undefined,
                    name : '',
                    account : 0,
                    pwd : 0
                    // share_money : 0,
                    // original_money : 0,
                    // img_id: [],
                    // goods_text: ''
                },
                
                seetitle:'新增',
                seemodal: false,
                showModal:false,
                columns12: [                   
                    {title: '序号',type: 'index',align:'center'},                    
                    {title: '姓名',key:'name',align:'center'},
                    {title: '手机号',key:'account',align:'center'},
                    {title: '编号',key:'number',align:'center'},             
                    // {title: '商品图片',slot:'img',align:'center'},                                       
                    {title: '订单',slot:'order_count',align:'center'},                   
                    {title: '卡卷',slot:'coupon_count',align:'center'},                       
                    {title: '分销',slot:'share_count',align:'center'},                       
                    {title: '亲属',slot:'relatives_count',align:'center'},  

                    {title: '操作',slot: 'action',width: 320,fixed: 'right',align:'center'}
                ],
                
                data6: [],
                hexiaoId:'',
                storeList:[],
                 // !订单
                orderModal: false,
                orderList:[],
                orderPageForm:{
                    page:1,
                    limit:10,
                    member_id:'',
                },
                ordertotal:0,
                orderColumns: [                   
                    {title: '序号',type: 'index',align:'center'},                    
                    {title: '套餐名称',slot:'goods',align:'center'},
                    {title: '订单金额',key:'money',align:'center'},                    
                    {title: '付款时间',key:'updated_at',align:'center'},                    
                    {title: '会员',slot:'member',align:'center'},    
                    {title: '状态',key:'status',align:'center'},         
                    {title: '操作',slot: 'action',width: 160,fixed: 'right',align:'center'}
                ],
                // !卡卷
                couponModal: false,
                couponSelectList:[],
                goodsListCoupon:[],
                goodsPageForm:{
                    page:1,
                    limit:10,
                    member_id:'',
                },
                goodstotal:0,
                couponColumns: [                   
                    {title: '序号', type: 'index',align:'center'},                    
                    {title: '卡卷名',slot:'coupon',align:'center'},   
                    {title: '状态',key:'status',align:'center'},
                    {title: '发放时间',key:'created_at',align:'center'},
                    {title: '有效期',key:'deadline',align:'center'},
                    {title: '核销时间',slot: 'hexiao_time',align:'center'},         
                    {title: '操作',slot: 'action',fixed: 'right',align:'center'}
                ],
                submitformdata:{
                    member_id:'',
                    num:'',
                    coupon_id:'',
                    phone:''
                },
                 // !分销 share
                 shareModal: false, 
                 shareList:[],
                 sharePageForm:{
                    page:1,
                    limit:10,
                    member_id:'',
                },
                sharetotal:0,
                shareColumns: [                   
                    {title: '序号',type: 'index',align:'center'},                    
                    {title: '订单id',key:'order_id',align:'center'},  
                    {title: '分享人',slot:'share',align:'center'},  
                    {title: '套餐名称',slot:'goods',align:'center'},      
                    {title: '会员名',slot:'member',align:'center'},        
                    {title: '分销金额',key:'money',align:'center'},                           
                    {title: '状态',key:'status',align:'center'},                    
                    {title: '订单时间',key:'created_at',align:'center'},         
                    // {title: '操作',slot: 'action',width: 220,fixed: 'right',align:'center'}
                ], 
                 // !亲属  relatives
                 relativesModal: false, 
                 relativesList:[],
                 relativesPageForm:{
                    page:1,
                    limit:10,
                    member_id:'',
                },
                relativestotal:0,
                relativesColumns: [                   
                    {title: '序号',key: 'id',align:'center'},                    
                    {title: '亲属名',slot:'member',align:'center'},
                    {title: '亲属账户',slot:'member1',align:'center'},            
                    {title: '操作',slot: 'action',width: 160,fixed: 'right',align:'center'}
                ] 
            }
        },
        created(){
            this.get_member_list()
            // this.get_coupon_select()

        },
        computed: {
            searchContent(){
                return this.search()
            },
        },
        methods:{
            qrcode(e){
                get_qrcode({id:e.id}).then(res=>{
                    window.open(res.data.data)
                })
            },
            goshowcoupon(e){
                this.goodsPageForm.member_id = e
                // this.submitformdata.member_id = e
                this.goodsPageForm.page = 1
                this.get_member_coupon_list()
                this.couponModal = true
            },
            goshowOrder(e){
                this.orderPageForm.member_id = e
                this.goodsPageForm.page = 1
                this.get_member_order_list()
                this.orderModal = true
            },
            goshowShare(e){
                this.sharePageForm.member_id = e
                this.sharePageForm.page = 1
                this.get_member_share_list()
                this.shareModal = true
            },
            goshowRelatives(e){
                this.relativesPageForm.member_id = e
                this.relativesPageForm.page = 1
                this.get_member_relatives_list()
                this.relativesModal = true
            },
            
            get_member_coupon_list(){
                get_member_coupon_list(this.goodsPageForm).then(res=>{
                    this.goodsListCoupon = res.data.data
                    this.goodstotal = res.data.total
                })
            },
            get_member_order_list(){
                get_order_member_list(this.orderPageForm).then(res=>{
                    this.orderList = res.data.data
                    this.ordertotal = res.data.total
                })
            },            
            get_member_share_list(){
                get_member_share_list(this.sharePageForm).then(res=>{
                    this.shareList = res.data.data
                    this.sharetotal = res.data.total
                })
            },
            get_member_relatives_list(){
                get_member_relatives_list(this.relativesPageForm).then(res=>{
                    this.relativesList = res.data.data
                    this.relativestotal = res.data.total
                })
            },
            orderPageChange(e){
                this.orderPageForm.page = e
                this.get_member_order_list()
            },
            goodsPageChange(e){
                this.goodsPageForm.page = e
                this.get_member_coupon_list()
            },
            sharePageChange(e){
                this.sharePageForm.page = e
                this.get_member_share_list()
            },
            relativesPageChange(e){
                this.relativesPageForm.page = e
                this.get_member_relatives_list()
            },
            member_del_relatives(e){
                this.$Modal.confirm({
                    title: '删除提示',
                    content: '请问您是否确认删除此条亲属人，删除后无法恢复！！！',
                    onOk: () => {
                        member_del_relatives({id:e}).then(res => {
                            this.$Message.success(res.msg)
                            this.get_member_relatives_list()
                            this.get_member_list()
                        }).catch(res => {
                            this.$Message.error(res.msg)
                        })
                    },
                    onCancel: () => {

                    }
                });
            },
            get_coupon_select(){
                get_coupon_select().then(res=>{
                    this.couponSelectList = res.data
                })
            },
            addfwm(){
                if(!this.submitformdata.phone){
                    return this.$Message.error('请输入亲属卡手机')
                } 
                // this.relativesPageForm.member_id
                let data = {
                    member_id: this.relativesPageForm.member_id,
                    phone:this.submitformdata.phone
                }       
                member_add_relatives(data).then(res=>{     
                    this.$Message.success(res.msg)
                    this.showfwm = false
                    this.get_member_relatives_list() 
                    this.get_member_list()
                }).catch(err=>{
                    this.$Message.error(err.msg)    
                })
            },
           
            reast(){
                this.formData.id = undefined
                this.formData.name = ''
                this.formData.account = ''
                this.formData.pwd = ''
                // this.formData.money = 0
                // this.formData.share_money = 0
                // this.formData.original_money = 0
                // this.formData.img_id = ''
                // this.formData.goods_text = ''
                // this.accessoriesList = []
                return
               
            },
            searhList(){                       
                this.pageForm.page = 1
                this.get_member_list()
            },
           
            //富文本
            getEnContentchange(detailData) {
                // console.log('detailData',detailData)
                // this.formdata.goods_text = detailData.editorData;
            },
            getEnContent(detailData) {
                
                console.log('detailData',detailData)
                let that = this 
                that.formData.goods_text = detailData.editorData;
            },
            get_member_list(){
                let data = {
                    page:this.pageForm.page,
                    limit:this.pageForm.limit,
                    keywords: this.pageForm.keywords
                }
                get_member_list(data).then(res=>{
                    this.total = res.data.total
                    this.data6 = res.data.data
                })
            },
            store_select_list(){
                store_select_list().then(res=>{
                    this.storeList = res.data
                })
            },
            goHexiao(e){
                this.hexiaoId = e.id
                this.showModal = true
            },
            sureHexiao(){
                handle({id:this.hexiaoId}).then(res=>{
                    this.$Message.success(res.msg)
                    this.showModal = false
                    this.get_member_list()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            getInfo(){
                get_info({uid:this.uid}).then(res=>{
                    this.avatarView = res.data.avatar_url
                    this.formData = res.data
                })
            },
            search(){
                this.pageForm.keywords = this.$store.state.searchContent
                this.get_member_list()
            },
            goFWM(){
                this.submitformdata.phone = ''
                // this.submitformdata.coupon_id = ''             
                this.showfwm = true
            },
             seeBtn(x,e){
                this.reast()
                if (x =='add') {
                    this.seetitle = '新增'                   
                }else{
                    this.formData.id = e.id
                    this.formData.name = e.name
                    this.formData.account = e.account  
                    this.seetitle = '修改'
                }
                this.seemodal = true
            },
            write_off(e) {
                console.log('e',e)
                this.$Modal.confirm({
                    title: '提示',
                    content: '是否确认核销该卡卷',
                    onOk: () => {                    	
                        // 1.使用 2取消使用
                        write_off({ id: e }).then(res => {
                            this.$Message.success(res.msg)
                            this.get_member_coupon_list()
                            this.get_member_list()
                        }).catch(err => {
                            this.$Message.error(err.msg)
                            this.get_member_coupon_list()
                            this.get_member_list()
                        })
                    },
                    onCancel: () => {
                    }
                });
            },
           
            addBtn(){
                if (this.seetitle == '查看') {
                    this.seemodal = false
                }else{
                    if(!this.formData.name){
                        return this.$Message.error('请输入姓名')
                    }
                    if(!this.formData.account){
                        return this.$Message.error('请输入手机号')
                    }
                    if(!this.formData.pwd && this.seetitle == '新增'  ){
                        return this.$Message.error('请输入密码')
                    }
                    // if(this.formData.years == null||this.formData.years ==0){
                    //     return this.$Message.error('请输入年限')
                    // }   
                    // if(this.formData.money == null||this.formData.money ==0){
                    //     return this.$Message.error('请输入套餐价格')
                    // }                    
                    // if(this.formData.share_money == null||this.formData.share_money ==0){
                    //     return this.$Message.error('请输入分销金额')
                    // }  
                    // if(this.formData.original_money == null||this.formData.original_money ==0){
                    //     return this.$Message.error('请输入原价')
                    // }  
                    // if( this.accessoriesList.length == 0){
                    //     return this.$Message.error('请选择商品图片')
                    // }
                    // if(!this.formData.goods_text){
                    //     return this.$Message.error('请输入商品详情')
                    // }
                    
                    
                    // this.formData.img_id  = ''
                    // let imgarr= []
                    // this.accessoriesList.forEach(item=>{
                    //     imgarr.push(item.id)
                    // })                  
                    // this.formData.img_id =  imgarr.join()
                    // this.formData.img_id = []
                    // this.accessoriesList.forEach(item=>{
                    //     this.formData.img_id .push(item.id)
                    // })
                    member_create(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.get_member_list()
                        this.seemodal = false
                        this.reast()
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }
            },
            //退单
            subTemplete(e) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '是否确认退单该订单',
                    onOk: () => {                    	
                        // 1.使用 2取消使用
                        order_refund({ id: e }).then(res => {
                            this.$Message.success(res.msg)
                            this.get_member_order_list()
                            this.get_member_list()
                        }).catch(err => {
                            this.$Message.error(err.msg)
                            this.get_member_order_list()
                            this.get_member_list()
                        })
                    },
                    onCancel: () => {
                    }
                });
            },
            statusBtn(){
                if (this.contentTexe=='确认禁用此账户') {
                    edit_status({uid:this.uid,status:0}).then(res=>{
                        this.get_member_list()
                        this.$Message.success(res.msg)
                        this.showModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else{
                    edit_status({uid:this.uid,status:1}).then(res=>{
                        this.get_member_list()
                        this.$Message.success(res.msg)
                        this.showModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }
            },
            delavatar(){
                this.formData.avatar = ''
                this.avatarView = ''
            },
            delpictures(item,index){
                let data = {uid:this.uid,id:item.id}
                del_album(data).then(res=>{
                    this.formData.picture_library.splice(index,1)
                })
            },
            closeNumber(status,e){
                goods_able({id:e.id,is_able:status}).then(res => {
                    this.$Message.success(res.msg)
                    this.get_member_list()
                }).catch(res => {
                    this.$Message.error(res.msg)
                    this.get_member_list()
                })
            },
            member_del(e){
                this.$Modal.confirm({
                    title: '删除提示',
                    content: '请问您是否确认删除此条套餐，删除后无法恢复！！！',
                    onOk: () => {
                        member_del({id:e}).then(res => {
                            this.$Message.success(res.msg)
                            this.get_member_list()
                        }).catch(res => {
                            this.$Message.error(res.msg)
                        })
                    },
                    onCancel: () => {

                    }
                });

            },
           
            //文件上传类型错误
            handleFormatError () {
                this.$Message.warning('暂不支持上传此格式');
            },
            closeForm(){
                this.reast()               
            },
            closefwm(){
                this.member_id = ''
            },
            //编辑图片上传图片成功
           uploadImgSuccess (e) {
                this.accessoriesList.push(e.data)
                this.$Message.destroy()
                this.$Message.success('上传成功');            
            },
            // 文件上传时
            handleProgress(){
                const msg = this.$Message.loading({
                    content: '上传中...',
                    duration: 0
                });
                setTimeout(msg, 3000);
            },
            pageChange(e){
                this.pageForm.page = e
                this.get_member_list()
            },
            
            delThis(index){
                this.accessoriesList.splice(index,1)             
            },
        }
    }
</script>

<style scoped>
.search_cont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 12px;
}
.search_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0px;

}
.search_herad {
    width: 70px;
    text-align: end;
    font-weight: 600;
}
    .content{
        width: 100%;
        height: calc(100vh - 104px);
        overflow: auto;
        padding: 20px 40px;
        background: #FFF;
        border-radius: 8px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .modal_contene{
        max-height: 500px;
        overflow: auto;
    }
    .user_pic_box{
        margin: 10px 0;
        width: 70px;
        height: 70px;
    }
    .user_pic_box img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
    .Photo_wall{
        display: flex;
        align-items: center;
    }
    .photo_box img{
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 5px;
    }
    .btn{
        background: #4877E8;
        color: #fff;
    }
    .head_portrait{
        display: flex;
        flex-wrap: wrap;
    }
    .viewimg{
        position: relative;
        width: 80px;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
    }
    .upload-style {
        width: 200px;
        height: 150px;
        border-radius: 4px;
        border: 1px dashed #8a8f97;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }
    .img-list {
        width: 120px;
        height: 120px;
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;    
    }
    .show-img-list  img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }

    .img-list  img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
    .img-list .close {
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
        color: #dd0000;
        display: none;
    }
    .img-list:hover .close {
        display: inline-block;
    }
    .viewimg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .absolu_close:hover{
        opacity: 1;
    }
    .absolu_close{
        cursor: pointer;
        position: absolute;
        top: 0;
        opacity: 0;
        left: 0;
        width: 80px;
        height: 80px;
        background: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload_img{
        cursor: pointer;
        width: 80px;
        height: 80px;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .upload_img_txt{
        margin-top: -10px;
        font-size: 12px;
        color: #999;
    }
    .just_content{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<style>
    .ivu-table td, .ivu-table th {
        border-bottom: 1px solid #F0F6FC;
        color: #74798C;
    }
    .ivu-table:before{
        background: unset;
    }
    .ivu-modal-content{
        overflow: hidden;
    }
    .ivu-modal-header{
        /* background: #4877E8; */
    }
    .ivu-modal-close .ivu-icon-ios-close{
        color: #333;
    }
    .ivu-modal-footer{
        border-top: unset;
    }
</style>